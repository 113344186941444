/**
 * Classe des URLs.
 */
export class URLS {
  /**
   * URL de base ou la racine.
   */
  public static BASE_URL = '/comptas'
  public static AUTH = URLS.BASE_URL + '/authenticate'
  public static USER = URLS.BASE_URL + '/users'
  public static ROLE = URLS.BASE_URL + '/roles'
  public static STRUCTURE = URLS.BASE_URL + '/structure'
  public static CATEGORIE = URLS.BASE_URL + '/categorie'
  public static PROFIL = URLS.BASE_URL + '/profil'
  public static CURRENT_USER_INFO = URLS.USER + '/current'
  public static RAPPORT = URLS.BASE_URL + '/rapports'
  public static DASHBOARD = URLS.BASE_URL + '/dashboard'
  public static REPORTING = URLS.BASE_URL + '/reporting'
  public static LOG = URLS.BASE_URL + '/log'
  public static COMPTE = URLS.BASE_URL + '/compte'
  public static TRANSACTION = URLS.BASE_URL + '/transaction'
  public static PARTENARIAT = URLS.BASE_URL + '/partenariat'
  public static OPERATION = URLS.BASE_URL + '/operation'
  public static MODE_REGLEMENT = URLS.BASE_URL + '/mode-reglement'
  public static CLIENT = URLS.BASE_URL + '/client'
  public static FACTURE = URLS.BASE_URL + '/facture'
}


/**
 * Constantes des variables stockées dans
 * le navigateur en local.
 */
export class YLOCALSTORAGE {
  public static TOKEN_NAME = 'yl-auth-token';
  public static PREVIOUS_URL = 'yl-previous-url';
}
