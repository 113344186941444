<div class="row">
  <div class="col-12">
    <div class="modal-header">
      <h4 class="modal-title">
        <i [ngClass]="type=='warning' ? 'ft-alert-octagon' : type=='info' ? 'icon-info': 'ft-danger'"></i>
        Confirmation</h4>
      <button (click)="activeModal.dismiss('CANCEL')" aria-label="Close" class="close" type="button">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <p>{{message}}</p>
    </div>
    <div class="modal-footer">
      <button (click)="activeModal.dismiss('CANCEL')" class="btn btn-outline-secondary" type="button">Annuler</button>
      <button (click)="activeModal.close('DONE')" class="btn btn-outline-primary" type="button">Confirmer</button>
    </div>
  </div>
</div>
