<nav [ngClass]="{'navbar-brand-center': menuPosition === 'Top', 'navbar-static': menuPosition === 'Side' &&
  config?.layout.navbar.type === 'Static' , 'navbar-sticky': menuPosition === 'Side' &&
  config?.layout.navbar.type === 'Fixed', 'fixed-top': isSmallScreen && menuPosition === 'Top'}"
     class="navbar navbar-expand-lg navbar-light header-navbar {{transparentBGClass}}">
  <div class="container-fluid navbar-wrapper">
    <div class="navbar-header d-flex">
      <div (click)="toggleSidebar()"
           class="navbar-toggle menu-toggle d-xl-none d-block float-left align-items-center justify-content-center"
           data-toggle="collapse">
        <i class="ft-menu font-medium-3"></i>
      </div>
      <ul (clickOutside)="toggleSearchOpenClass(false)" class="navbar-nav">
        <li class="nav-item mr-2 d-none d-lg-block">
          <a (click)="ToggleClass()" appToggleFullscreen class="nav-link apptogglefullscreen" href="javascript:"
             id="navbar-fullscreen"><i class=" {{toggleClass}} font-medium-3"></i></a>
        </li>
        <li class="nav-item nav-search">
          <a (click)="toggleSearchOpenClass(true)" class="nav-link nav-link-search" href="javascript:"
             id="navbar-search"><i
            class="ft-search font-medium-3"></i></a>
          <div class="search-input {{searchOpenClass}}">
            <div class="search-input-icon">
              <i class="ft-search font-medium-3"></i>
            </div>
            <input #search (keydown.esc)="onEscEvent()" (keyup)="onSearchKey($event)" (keyup.enter)="onEnter()"
                   [appAutocomplete]="autocomplete" [formControl]="control"
                   autofocus class="input" data-search="template-search" placeholder="Explore Apex..."
                   tabindex="0" type="text"/>
            <div (click)="toggleSearchOpenClass(false)" class="search-input-close">
              <i class="ft-x font-medium-3"></i>
            </div>

            <app-autocomplete #autocomplete="appAutocomplete">
              <ng-template appAutocompleteContent>
                <ng-container *ngIf="(listItems | filter: control.value) as result">
                  <app-option #searchResults (click)="redirectTo(option.url);" (mouseenter)="removeActiveClass()"
                              *ngFor="let option of result"
                              [url]="option.url" [value]="option.name">
                    <div class="d-flex align-items-center justify-content-between w-100">
                      <div class="d-flex align-items-center justify-content-start">
                        <i class="{{option.icon}} mr-2"></i>
                        <span>{{ option.name }}</span>
                      </div>
                    </div>
                  </app-option>
                  <app-option *ngIf="!result.length" class="no-result">Aucun résultat trouvé.</app-option>
                </ng-container>
              </ng-template>
            </app-autocomplete>
          </div>
        </li>
      </ul>
      <div class="navbar-brand-center">
        <div class="navbar-header">
          <ul class="navbar-nav">
            <li class="nav-item">
              <div class="logo">
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="navbar-container">
      <div class="collapse navbar-collapse d-block" id="navbarSupportedContent">
        <ul class="navbar-nav">
          <li class="dropdown nav-item" display="static" ngbDropdown placement="bottom-left">
            <a class="nav-link dropdown-toggle dropdown-notification p-0 mt-2" data-toggle="dropdown" href="javascript:"
               id="drp-notification" ngbDropdownToggle><i class="ft-bell font-medium-3"></i>
              <span *ngIf="etatAbonnement?.message" class="notification badge badge-pill badge-danger">1</span></a>
            <ul *ngIf="etatAbonnement?.message"
                class="notification-dropdown dropdown-menu dropdown-menu-media dropdown-menu-right m-0 overflow-hidden"
                ngbDropdownMenu>

              <li class="dropdown-menu-header">
                <div class="dropdown-header d-flex justify-content-between m-0 px-3 py-2 white bg-primary">
                  <div class="d-flex">
                    <i class="ft-bell font-medium-3 d-flex align-items-center mr-2"></i><span class="noti-title">1 Nouvelles
                      Notifications</span>
                  </div>
                  <span class="text-bold-400 cursor-pointer">...</span>
                </div>
              </li>
              <li [perfectScrollbar] class="scrollable-container">
                <div class="d-flex justify-content-between cursor-pointer read-notification">
                  <div class="media d-flex align-items-center">
                    <div class="media-left">
                      <div class="avatar bg-info bg-lighten-4 mr-3 p-1">
                        <div class="avatar-content font-medium-2">
                          <i class="ft-align-left text-info"></i>
                        </div>
                      </div>
                    </div>
                    <div class="media-body">
                      <h6 class="m-0">
                        <span>{{etatAbonnement?.message}}
                        </span><small class="grey lighten-1 font-italic float-right"> ...</small>
                      </h6>
                    </div>
                  </div>
                </div>
              </li>
            </ul>

          <li class="dropdown nav-item mr-1" display="static" ngbDropdown placement="bottom-left">
            <a class="nav-link dropdown-toggle user-dropdown d-flex align-items-end" data-toggle="dropdown"
               href="javascript:" id="dropdownBasic2" ngbDropdownToggle>
              <div [title]="currentUserInfo?.prenom + ' ' + currentUserInfo?.nom" class="user d-md-flex d-none mr-2">
                <span class="text-right"><b>{{currentUserInfo?.codeStructure}}</b> &nbsp;
                  | &nbsp; {{currentUserInfo?.prenom + ' ' + (currentUserInfo?.nom| uppercase)}}</span>
                <span class="text-right text-muted font-small-3">
                  <i class="ft-disc text-success"></i> &nbsp; En ligne</span>
              </div>
              <img *ngIf="currentUserInfo?.sexe ==='MASCULIN' " alt="avatar" class="avatar" height="35"
                   src="assets/img/portrait/small/avatar-y.png" width="35"/>
              <img *ngIf="currentUserInfo?.sexe ==='FEMININ' " alt="avatar" class="avatar" height="35"
                   src="assets/img/portrait/small/avatar-x.png" width="35"/>
            </a>
            <div aria-labelledby="dropdownBasic2" class="dropdown-menu text-left dropdown-menu-right m-0 pb-0"
                 ngbDropdownMenu>
              <a (click)="updateProfil()" class="dropdown-item">
                <div class="d-flex align-items-center">
                  <i class="ft-edit mr-2"></i><span>Profil</span>
                </div>
              </a>
              <div class="dropdown-divider"></div>
              <a (click)="logout()" class="dropdown-item">
                <div class="d-flex align-items-center">
                  <i class="ft-power mr-2"></i><span>Déconnexion</span>
                </div>
              </a>
            </div>
          </li>
          <li class="nav-item d-none d-lg-block mr-2 mt-1">
            <a (click)="toggleNotificationSidebar();" class="nav-link notification-sidebar-toggle"><i
              class="ft-align-right font-medium-3"></i></a>
          </li>
        </ul>
      </div>
    </div>
  </div>


</nav>
