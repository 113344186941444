<!-- START Notification Sidebar-->
<aside [ngClass]="{open: isOpen}" class="notification-sidebar d-none d-sm-none d-md-block" id="notification-sidebar">
  <a (click)="onClose()" class="notification-sidebar-close">
    <i class="ft-x font-medium-3 grey darken-1"></i>
  </a>
  <div [perfectScrollbar] class="side-nav notification-sidebar-content">
    <div class="row">
      <div class="col-12 notification-tab-content">
        <nav #nav="ngbNav" class="nav-tabs" ngbNav>
          <ng-container class="nav-item" ngbNavItem>
            <a class="nav-link" ngbNavLink>Activités</a>
            <ng-template ngbNavContent>
              <div aria-expanded="true" aria-labelledby="base-tab1" class="row tab-pane" id="activity-tab"
                   role="tabpanel">
                <div class="col-12" id="activity">
                  <h5 class="my-2 text-bold-500">Activités récentes</h5>
                  <div class="timeline-left timeline-wrapper" id="timeline-2">
                    <ul class="timeline">
                      <li class="timeline-line mt-4"></li>
                      <li class="timeline-item">
                        <div class="timeline-badge">
                          <span class="bg-primary bg-lighten-4" data-placement="right" data-toggle="tooltip"
                                title="Notification épinglée.">
                            <i class="fa fa-map-pin primary"></i>
                          </span>
                        </div>
                        <div class="activity-list-text">
                          <h6 class="mb-1">
                            <span>Mise à jour disponible</span>
                            <span class="float-right grey font-italic font-small-2">Très bientôt</span>
                          </h6>
                          <p class="mt-0 mb-2 font-small-3 notification-note">Nous travaillons d'arache-pieds pour vous
                            offrir les
                            meilleures fonctionnalités.</p>
                        </div>
                      </li>
                      <li *ngFor="let l of logs" class="timeline-item">
                        <div class="timeline-badge">
                          <span class="bg-primary bg-lighten-4" data-placement="right" data-toggle="tooltip"
                                title="Portfolio project work">
                            <i *ngIf="l.typeAction === 'CREATE' " class="ft-plus primary"></i>
                            <i *ngIf="l.typeAction === 'READ' " class="ft-eye primary"></i>
                            <i *ngIf="l.typeAction === 'DELETE' " class="ft-trash-2 primary"></i>
                            <i *ngIf="l.typeAction === 'UPDATE' " class="ft-edit-2 primary"></i>
                            <i *ngIf="l.typeAction === 'PRINT' " class="ft-printer primary"></i>
                          </span>
                        </div>
                        <div class="activity-list-text">
                          <h6 class="mb-1">
                            <span>{{formatTitle(l)}}</span>
                            <span class="float-right grey font-italic font-small-2"> {{l.createdBy}}
                              | {{formatDate(l)}}</span>
                          </h6>
                          <p class="mt-0 mb-2 font-small-3">
                            {{l.details}}
                          </p>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </ng-template>
          </ng-container>
        </nav>
        <div [ngbNavOutlet]="nav" class="mt-2"></div>
      </div>
    </div>
  </div>
</aside>
<!-- END Notification Sidebar-->
