/**
 * Correspond au type d'information.
 */
export enum ETypeInformation {
  INFORMATION_GENERALE = 'INFORMATION_GENERALE',
  ABSENCE = 'ABSENCE',
  FELICITATION = 'FELICITATION',
  URGENCE = 'URGENCE',
  CADEAUX = 'CADEAUX',
}

/**
 * Correspond au type de rapports.
 */
export enum ETypeRapport {
  BILAN_OPERATION = 'BILAN_OPERATION',
  OPERATION_PAR_CATEGORIE = 'OPERATION_PAR_CATEGORIE',
  OPERATION_PAR_CLIENT = 'OPERATION_PAR_CLIENT',
  RECU_PAIEMENT = 'RECU_PAIEMENT',
  FACTURE_LIVRAISON = 'FACTURE_LIVRAISON'
}

/**
 * Correspond au type de catégorie.
 */
export enum ETypeCategorie {
  DEPENSE = 'DEPENSE',
  RECETTE = 'RECETTE'
}

/**
 * Correspond au sens d'une opération.
 */
export enum ESensOperation {
  DEBIT = 'DEBIT',
  CREDIT = 'CREDIT'
}

/** Liste des roles pour accéder aux menus.*/
export enum YlAuthority {
  CONNECT = 'CS_CONNECT',
  SUPER_ADMIN = 'CS_ADMIN',
  IMPRESSION = 'CS_IMPRESSION',
  LOCAL_ADMIN = 'CS_LOCAL_ADMIN',
  PAIEMENT = 'CS_PAIEMENT',
  VIEW_DEPENSE = 'CS_VIEW_DEPENSE',
  EDIT_DEPENSE = 'CS_EDIT_DEPENSE',
  VIEW_RECETTE = 'CS_VIEW_RECETTE',
  EDIT_RECETTE = 'CS_EDIT_RECETTE',

  VIEW_FACTURE = 'CS_VIEW_FACTURE',
  EDIT_FACTURE = 'CS_EDIT_FACTURE'
}
