import {Component, OnDestroy, OnInit} from '@angular/core';
import {Subscription} from 'rxjs';
import {NavigationEnd, NavigationStart, Router} from '@angular/router';
import {filter} from 'rxjs/operators';
import {NgSelectConfig} from '@ng-select/ng-select';
import {LoaderService} from './shared/utils/utils';
import {NgxSpinnerService} from 'ngx-spinner';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html'
})
export class AppComponent implements OnInit, OnDestroy {

  subscription: Subscription;
  spinnerVisible = false;

  constructor(private router: Router,
              private config: NgSelectConfig,
              private ngxSpinnerService: NgxSpinnerService,
              private loaderService: LoaderService) {

    // Configuration globale de ng-select
    this.config.notFoundText = 'Élément introuvable';
    this.config.appendTo = 'body';
    // set the bindValue to global config when you use the same
    // bindValue in most of the place.
    // You can also override bindValue for the specified template
    // by defining `bindValue` as property
    // Eg : <ng-select bindValue="some-new-value"></ng-select>
    // this.config.bindValue = 'value';

    // Loader configuration
    // Removes ongoing http requests when user navigates to other page
    router.events.pipe(filter((event => event instanceof NavigationStart)))
      .subscribe(() => (this.loaderService.setRequests([])));
  }

  ngOnInit() {
    this.subscription = this.router.events
      .pipe(
        filter(event => event instanceof NavigationEnd)
      )
      .subscribe(() => window.scrollTo(0, 0));

    this.loaderService.isLoading.subscribe(res => {
      if (res === true) {
        // console.log(`>>>>Show spinner...`)
        this.ngxSpinnerService.show('http-loading');
      } else {
        // console.log(`<<<<Hide spinner...`)
        this.ngxSpinnerService.hide('http-loading');
      }
    });

    setTimeout(() => {
      this.spinnerVisible = true;
    }, 10000);
  }


  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }


}
