import {Injectable} from '@angular/core';
import {ToastrService} from 'ngx-toastr';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {

  constructor(private toastr: ToastrService) {

  }

  /**
   * Afficher une alerte.
   * @param type
   * @param title
   * @param msg
   */
  alert(type: 'info' | 'warn' | 'error' = 'info', title: string, msg: string) {
    switch (type) {
      case 'error':
        this.error(title, msg);
        break;
      case 'info':
        this.info(title, msg);
        break;
      case 'warn':
        this.warning(title, msg);
        break;
    }
  }

  /**
   * Afficher un message d'information.
   * @param title
   * @param msg
   */
  info(title: string, msg: string) {
    this.toastr.info(msg, title, {
      positionClass: 'toast-top-right'
    });
  }

  /**
   * Afficher un message de réussite/succès.
   * @param title
   * @param msg
   */
  success(title: string, msg: string) {
    this.toastr.success(msg, title, {
      positionClass: 'toast-top-right'
    });
  }

  /**
   * Afficher un message d'avertissement.
   * @param title
   * @param msg
   */
  warning(title: string, msg: string) {
    this.toastr.warning(msg, title, {
      positionClass: 'toast-top-right'
    });
  }

  /**
   * Afficher un message d'erreur.
   * @param title
   * @param msg
   */
  error(title: string, msg: string) {
    this.toastr.error(msg, title, {
      positionClass: 'toast-top-right'
    });
  }

  /**
   * Afficher un message d'information static au milieu
   * @param type
   * @param title
   * @param msg
   */
  pinned(type: 'info' | 'warn' | 'error' = 'info', title: string, msg: string) {
    switch (type) {
      case 'error':
        this.toastr.error(msg, title, {
          positionClass: 'toast-top-full-width',
          tapToDismiss: false,
          timeOut: 50000,
          disableTimeOut: true,
          closeButton: true
        });
        break;
      case 'info':
        this.toastr.info(msg, title, {
          positionClass: 'toast-top-full-width',
          tapToDismiss: false,
          timeOut: 50000,
          disableTimeOut: true,
          closeButton: true
        });
        break;
      case 'warn':
        this.toastr.warning(msg, title, {
          positionClass: 'toast-top-full-width',
          tapToDismiss: false,
          timeOut: 50000,
          disableTimeOut: true,
          closeButton: true
        });
        break;
    }

  }

}
