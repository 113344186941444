import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RouterModule} from '@angular/router';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {OverlayModule} from '@angular/cdk/overlay';

import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {TranslateModule} from '@ngx-translate/core';
import {PerfectScrollbarModule} from 'ngx-perfect-scrollbar';
import {ClickOutsideModule} from 'ng-click-outside';

import {AutocompleteModule} from './components/autocomplete/autocomplete.module';
import {PipeModule} from 'app/shared/pipes/pipe.module';

// COMPONENTS
import {FooterComponent} from './footer/footer.component';
import {NavbarComponent} from './navbar/navbar.component';
import {VerticalMenuComponent} from './vertical-menu/vertical-menu.component';
import {CustomizerComponent} from './customizer/customizer.component';
import {NotificationSidebarComponent} from './notification-sidebar/notification-sidebar.component';

// DIRECTIVES
import {ToggleFullscreenDirective} from './directives/toggle-fullscreen.directive';
import {SidebarLinkDirective} from './directives/sidebar-link.directive';
import {SidebarDropdownDirective} from './directives/sidebar-dropdown.directive';
import {SidebarAnchorToggleDirective} from './directives/sidebar-anchor-toggle.directive';
import {SidebarDirective} from './directives/sidebar.directive';
import {TopMenuDirective} from './directives/topmenu.directive';
import {TopMenuLinkDirective} from './directives/topmenu-link.directive';
import {TopMenuDropdownDirective} from './directives/topmenu-dropdown.directive';
import {TopMenuAnchorToggleDirective} from './directives/topmenu-anchor-toggle.directive';
import {ConfirmationComponent} from './components/confirmation/confirmation.component';
import {NgxWebstorageModule} from 'ngx-webstorage';
import {InputPasswordDirective} from './directives/input-password.directive';
import {IConfig, NgxMaskModule} from 'ngx-mask';
import {HasAnyAuthorityDirective} from './directives/has-any-authority.directive';

const maskConfig: Partial<IConfig> = {
  validation: false,
  thousandSeparator: ' '
};

@NgModule({
  exports: [
    CommonModule,
    FooterComponent,
    NavbarComponent,
    VerticalMenuComponent,
    CustomizerComponent,
    NotificationSidebarComponent,
    ToggleFullscreenDirective,
    SidebarDirective,
    TopMenuDirective,
    NgbModule,
    TranslateModule,
    ConfirmationComponent,
    InputPasswordDirective,
    NgxMaskModule,
    HasAnyAuthorityDirective
  ],
  imports: [
    RouterModule,
    CommonModule,
    NgbModule,
    TranslateModule,
    FormsModule,
    OverlayModule,
    ReactiveFormsModule,
    ClickOutsideModule,
    AutocompleteModule,
    PipeModule,
    PerfectScrollbarModule,
    NgxWebstorageModule.forRoot(),
    NgxMaskModule.forRoot(maskConfig),
    // NgxWebstorageModule.forRoot({ prefix: 'custom', separator: '.', caseSensitive:true })
    // The forRoot method allows to configure the prefix, the separator and the caseSensitive option used by the library
    // Default values:
    // prefix: "ngx-webstorage"
    // separator: "|"
    // caseSensitive: false
  ],
  declarations: [
    FooterComponent,
    NavbarComponent,
    VerticalMenuComponent,
    CustomizerComponent,
    NotificationSidebarComponent,
    ToggleFullscreenDirective,
    SidebarLinkDirective,
    SidebarDropdownDirective,
    SidebarAnchorToggleDirective,
    SidebarDirective,
    TopMenuLinkDirective,
    TopMenuDropdownDirective,
    TopMenuAnchorToggleDirective,
    TopMenuDirective,
    ConfirmationComponent,
    InputPasswordDirective,
    HasAnyAuthorityDirective
  ]
})
export class SharedModule {
}
