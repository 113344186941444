// tslint:disable-next-line:notAll-duplicate-imports TODO: figure out way to import momment global
import * as moment from 'moment';
import {NgbDateParserFormatter, NgbDateStruct} from '@ng-bootstrap/ng-bootstrap';
import {Injectable} from '@angular/core';

export class YlDateUtils {

  public static toFormatFromDate(date: Date, pattern: string = 'DD/MM/YYYY'): string {
    return moment(date).format(pattern);
  }


  public static patternToDate(dateString: string, pattern: string): Date {
    const date = moment(dateString, pattern).toDate();
    return new Date(Date.UTC(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate()));
  }

  public static unixToDate(unixStamp: number): Date {
    return moment(Number(unixStamp)).toDate();
  }

  public static sortArray(objectList: Array<any>, field: string, typCrois: string): Array<any> {
    let array: Array<any>;
    switch (typCrois) {
      case 'asc': {
        array = objectList.sort((a, b) => ((a[field] < b[field]) ? -1 : 1));
        break;
      }
      case 'desc': {
        array = objectList.sort((a, b) => ((a[field] > b[field]) ? -1 : 1));
        break;
      }
    }
    return array;
  }
}

/**
 * Classe de parsing de la date,
 * pour avoir le format jours/mois/année.
 */
@Injectable()
export class NgbDateCustomParserFormatter extends NgbDateParserFormatter {
  parse(value: string): NgbDateStruct {
    if (value) {
      const dateParts = value.trim().split('/');
      if (dateParts.length === 1 && isNumber(dateParts[0])) {
        return {day: toInteger(dateParts[0]), month: null, year: null};
      } else if (dateParts.length === 2 && isNumber(dateParts[0]) && isNumber(dateParts[1])) {
        return {
          day: toInteger(dateParts[0]),
          month: toInteger(dateParts[1]),
          year: null
        };
      } else if (dateParts.length === 3 && isNumber(dateParts[0]) && isNumber(dateParts[1]) && isNumber(dateParts[2])) {
        return {
          day: toInteger(dateParts[0]),
          month: toInteger(dateParts[1]),
          year: toInteger(dateParts[2])
        };
      }
    }
    return null;
  }

  format(date: NgbDateStruct): string {
    return date
      ? `${isNumber(date.day) ? padNumber(date.day) : ''}/${isNumber(date.month) ? padNumber(date.month) : ''}/${
        date.year
      }`
      : '';
  }
}

export function toInteger(value: any): number {
  return parseInt(`${value}`, 10);
}

export function isNumber(value: any): value is number {
  return !isNaN(toInteger(value));
}

export function padNumber(value: number) {
  if (isNumber(value)) {
    return `0${value}`.slice(-2);
  } else {
    return '';
  }
}
