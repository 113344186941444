import {Injectable} from '@angular/core';
import {HttpClient, HttpResponse} from '@angular/common/http';
import {Observable, Subject} from 'rxjs';
import {IUser} from '../data-model/yl-user';
import {URLS} from '../utils/contants';
import {YlAuthority} from '../data-model/enumerations';


@Injectable({providedIn: 'root'})
export class AccountService {
  private userIdentity: any;
  private authenticated = false;
  /** L'ID de la structure, de l'utilisateur.*/
  private idStructure = null;
  private authenticationState = new Subject<any>();

  constructor(private http: HttpClient) {
  }

  fetchCurrentUserInfo(): Observable<HttpResponse<IUser>> {
    return this.http.get<IUser>(URLS.CURRENT_USER_INFO, {observe: 'response'});
  }

  hasAnyAuthority(authorities: string[]): boolean {
    if (!this.authenticated || !this.userIdentity || !this.userIdentity.roles) {
      return false;
    }

    // tslint:disable-next-line:prefer-for-of
    for (let i = 0; i < authorities.length; i++) {
      if (this.userIdentity.roles.map(r => r.code).includes(authorities[i])) {
        return true;
      }
    }

    return false;
  }

  /**
   * Vérifie si l'utilisateur possède au moins, un de ces rôles.
   * @param authorities
   */
  hasAnyCiAuthority(authorities: YlAuthority[]): boolean {
    if (authorities && authorities.length > 0) {
      return this.hasAnyAuthority(authorities.map(i => {
        return i.toString()
      }));
    }
    return true;
  }

  /**
   * Vérifie si l'utilisateur a un rôle précis.
   * @param authority
   */
  hasCiAuthority(authority: YlAuthority): boolean {
    if (authority) {
      return this.hasAnyAuthority([authority.toString()]);
    }
    return false;
  }


  identity(force?: boolean): Promise<any> {
    if (force) {
      this.userIdentity = undefined;
    }

    // check and see if we have retrieved the userIdentity data from the server.
    // if we have, reuse it by immediately resolving
    if (this.userIdentity) {
      return Promise.resolve(this.userIdentity);
    }

    // retrieve the userIdentity data from the server, update the identity object, and then resolve.
    return this.fetchCurrentUserInfo()
      .toPromise()
      .then(response => {
        const account = response.body;
        if (account) {
          this.userIdentity = account;
          this.authenticated = true;
          this.idStructure = account.idStructure;
        } else {
          this.userIdentity = null;
          this.authenticated = false;
          this.idStructure = null;
        }
        this.authenticationState.next(this.userIdentity);
        return this.userIdentity;
      })
      .catch(err => {
        this.userIdentity = null;
        this.authenticated = false;
        this.idStructure = null;
        this.authenticationState.next(this.userIdentity);
        return null;
      });
  }

  getAuthenticationState(): Observable<any> {
    return this.authenticationState.asObservable();
  }

  getCurrentStructureId(): string {
    return this.idStructure;
  }

  logout() {
    this.userIdentity = null;
    this.authenticated = false;
    this.idStructure = null;
    this.authenticationState.next(this.userIdentity);
  }
}
