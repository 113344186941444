import {Component, OnDestroy, OnInit} from '@angular/core';
import {LayoutService} from '../services/layout.service';
import {Subscription} from 'rxjs';
import {ILog} from '../data-model/yl-log';
import {ComptasService} from '../services/comptas.service';
import * as moment from 'moment';
import {AccountService} from '../auth/account.service';

@Component({
  selector: 'app-notification-sidebar',
  templateUrl: './notification-sidebar.component.html',
  styleUrls: ['./notification-sidebar.component.scss']
})
export class NotificationSidebarComponent implements OnInit, OnDestroy {

  layoutSub: Subscription;
  isOpen = false;

  logs: ILog[]

  constructor(private layoutService: LayoutService,
              private accountService: AccountService,
              private ciAlertService: ComptasService) {

    this.layoutSub = layoutService.toggleNotiSidebar$.subscribe(
      open => {
        this.isOpen = open;
        if (open) {
          this.ciAlertService.fetchLogs({limit: 25, idStructure: this.accountService.getCurrentStructureId()})
            .subscribe(ds => {
              this.logs = ds;
            })
        }
      });
  }

  ngOnInit() {

  }

  ngOnDestroy() {
    if (this.layoutSub) {
      this.layoutSub.unsubscribe();
    }
  }

  formatDate(log: ILog): string {
    return moment(log.createdDate, undefined, 'fr').fromNow()
  }

  formatTitle(log: ILog) {
    switch (log.typeAction) {
      case 'READ':
        return 'Lecture';
      case 'CREATE':
        return 'Création';
      case 'UPDATE':
        return 'Modification';
      case 'DELETE':
        return 'Suppression';
      case 'PRINT':
        return 'Impression';
      default:
        return 'Actions';
    }
  }

  onClose() {
    this.layoutService.toggleNotificationSidebar(false);
  }

}
