import {Component, Input} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';

@Component({
    selector: 'app-confirmation',
    templateUrl: 'confirmation.component.html'
  }
)
export class ConfirmationComponent {

  /**
   * Le message de confirmation à afficher.
   */
  @Input()
  message = ''

  /**
   * Le type de confirmation.
   */
  @Input()
  type: 'warning' | 'danger' | 'info' = 'warning'

  constructor(public activeModal: NgbActiveModal) {
  }

}
