import {Routes} from '@angular/router';

// Route for content layout without sidebar, navbar and footer.

export const FULL_LAYOUT_ROUTES: Routes = [
  {
    path: 'pages',
    loadChildren: () => import('../../pages/content-pages/content-pages.module').then(m => m.ContentPagesModule)
  }
];
