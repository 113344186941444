import {AfterViewInit, ChangeDetectorRef, Component, ElementRef, HostListener, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {ROUTES} from './vertical-menu-routes.config';

import {Router} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import {customAnimations} from '../animations/custom-animations';
import {DeviceDetectorService} from 'ngx-device-detector';
import {ConfigService} from '../services/config.service';
import {Subscription} from 'rxjs';
import {LayoutService} from '../services/layout.service';
import {AccountService} from '../auth/account.service';
import {ComptasService} from '../services/comptas.service';
import {YlAuthority} from '../data-model/enumerations';

@Component({
  selector: 'app-sidebar',
  templateUrl: './vertical-menu.component.html',
  animations: customAnimations
})
export class VerticalMenuComponent implements OnInit, AfterViewInit, OnDestroy {

  @ViewChild('toggleIcon') toggleIcon: ElementRef;
  public menuItems: any[];
  level = 0;
  logoUrl = 'assets/img/logo.png';
  public config: any = {};
  layoutSub: Subscription;
  configSub: Subscription;
  perfectScrollbarEnable = true;
  collapseSidebar = false;
  resizeTimeout;
  protected innerWidth: any;

  constructor(
    private router: Router,
    public translate: TranslateService,
    private layoutService: LayoutService,
    private configService: ConfigService,
    private cdr: ChangeDetectorRef,
    private deviceService: DeviceDetectorService,
    private accountService: AccountService,
    private parametreService: ComptasService
  ) {
    this.config = this.configService.templateConf;
    this.innerWidth = window.innerWidth;
    this.isTouchDevice();
  }


  ngOnInit() {
    this.initSideBarItems();
  }

  ngAfterViewInit() {

    this.configSub = this.configService.templateConf$.subscribe((templateConf) => {
      if (templateConf) {
        this.config = templateConf;
      }
      this.loadLayout();
      this.cdr.markForCheck();

    });

    this.layoutSub = this.layoutService.overlaySidebarToggle$.subscribe(
      collapse => {
        if (this.config.layout.menuPosition === 'Side') {
          this.collapseSidebar = collapse;
        }
      });

  }

  @HostListener('window:resize', ['$event'])
  onWindowResize(event) {
    if (this.resizeTimeout) {
      clearTimeout(this.resizeTimeout);
    }
    this.resizeTimeout = setTimeout((() => {
      this.innerWidth = event.target.innerWidth;
      this.loadLayout();
    }).bind(this), 500);
  }

  loadLayout() {
    if (this.config.layout.sidebar.backgroundColor === 'white') {
      this.logoUrl = 'assets/img/logo-dark.png';
    } else {
      this.logoUrl = 'assets/img/logo.png';
    }

    if (this.config.layout.sidebar.collapsed) {
      this.collapseSidebar = true;
    } else {
      this.collapseSidebar = false;
    }
  }

  toggleSidebar() {
    const conf = this.config;
    conf.layout.sidebar.collapsed = !this.config.layout.sidebar.collapsed;
    this.configService.applyTemplateConfigChange({layout: conf.layout});

    setTimeout(() => {
      this.fireRefreshEventOnWindow();
    }, 300);
  }

  fireRefreshEventOnWindow = function () {
    const evt = document.createEvent('HTMLEvents');
    evt.initEvent('resize', true, false);
    window.dispatchEvent(evt);
  };

  closeSidebar() {
    this.layoutService.toggleSidebarSmallScreen(false);
  }

  isTouchDevice() {

    const isMobile = this.deviceService.isMobile();
    const isTablet = this.deviceService.isTablet();

    if (isMobile || isTablet) {
      this.perfectScrollbarEnable = false;
    } else {
      this.perfectScrollbarEnable = true;
    }

  }

  ngOnDestroy() {
    if (this.layoutSub) {
      this.layoutSub.unsubscribe();
    }
    if (this.configSub) {
      this.configSub.unsubscribe();
    }

  }

  private initSideBarItems() {
    const currentCdeId = this.accountService.getCurrentStructureId();
    // Vérifier l'état d'abonnement.
    this.parametreService.checkAbonnement(currentCdeId)
      .subscribe(p => {
        const expired = !p.upToDate;

        // Initialiser la visibilité en fonction des rôles de l'utilisateur, connecté.
        if (this.accountService.hasCiAuthority(YlAuthority.SUPER_ADMIN)) {
          this.menuItems = ROUTES.map(i => {
            i.visible = this.accountService.hasAnyCiAuthority(i.authorities);
            // 2 level
            i.submenu.map(sb => {
              sb.visible = this.accountService.hasAnyCiAuthority(sb.authorities);

              // 3 level
              sb.submenu.map(ssb => {
                ssb.visible = this.accountService.hasAnyCiAuthority(ssb.authorities);
              })
            })
            return i;
          });
        } else {
          this.menuItems = ROUTES.map(i => {
            i.visible = (this.accountService
                .hasAnyCiAuthority(i.authorities) && !expired)
              || (this.accountService
                  .hasAnyCiAuthority(i.authorities) && expired
                && (i.path.includes('paiement') || i.path.includes('home')));
            // 2 level
            i.submenu.map(sb => {
              sb.visible = this.accountService
                .hasAnyCiAuthority(sb.authorities) && !expired;

              // 3 level
              sb.submenu.map(ssb => {
                ssb.visible = this.accountService
                  .hasAnyCiAuthority(ssb.authorities) && !expired;
              })
            })
            return i;
          });
        }
      }, (err) => {
      });
  }

}
