<!-- Sidebar Header starts -->
<div *ngIf="config?.layout.menuPosition === 'Side'" class="sidebar-header">
  <div class="logo clearfix">
    <a [routerLink]="['/page']" class="logo-text float-left">
      <span class="align-middle text text-alert">Compta</span>
      <span class="align-middle text text-ci">S</span> </a>
    <a (click)="toggleSidebar()" class="nav-toggle d-none d-lg-none d-xl-block" href="javascript:"
       id="sidebarToggle">
      <i #toggleIcon [ngClass]="{
        'ft-toggle-left': config.layout.sidebar.collapsed,
        'ft-toggle-right': !config.layout.sidebar.collapsed
      }" class="toggle-icon"></i>
    </a>
    <a (click)="closeSidebar()" class="nav-close d-block d-lg-block d-xl-none" href="javascript:" id="sidebarClose">
      <i class="ft-x"></i>
    </a>
  </div>
</div>
<!-- Sidebar Header Ends -->

<!-- Sidebar Content starts -->
<div [disabled]="!perfectScrollbarEnable" [perfectScrollbar] class="sidebar-content main-menu-content">
  <div class="nav-container">
    <ul appSidebarDropdown class="navigation">
      <!-- First level menu -->
      <li *ngFor="let menuItem of menuItems" [hasSub]="!!menuItem.class.includes('has-sub')"
          [ngClass]="{'has-sub': !!menuItem.class.includes('has-sub')}" [parent]="menuItem.title"
          [path]="menuItem.path" [routerLinkActiveOptions]="{exact: false}"
          [routerLinkActive]="menuItem.submenu.length != 0 ? 'open' : 'active'"
          appSidebarlink level="{{level + 1}}">
        <ng-container *ngIf="menuItem.visible">
          <a *ngIf="menuItem.submenu.length > 0 && !menuItem.isExternalLink" appSidebarAnchorToggle>
            <i [ngClass]="[menuItem.icon]"></i>
            <span class="menu-title">{{menuItem.title | translate }}</span>
            <span *ngIf="menuItem.badge && menuItem.badge != '' "
                  [ngClass]="[menuItem.badgeClass]">{{menuItem.badge}}</span>
          </a>
          <a *ngIf="menuItem.submenu.length === 0 && !menuItem.isExternalLink" appSidebarAnchorToggle
             routerLink="{{menuItem.path}}">
            <i [ngClass]="[menuItem.icon]"></i>
            <span class="menu-title">{{menuItem.title | translate }}</span>
            <span *ngIf="menuItem.badge && menuItem.badge != '' "
                  [ngClass]="[menuItem.badgeClass]">{{menuItem.badge}}</span>
          </a>
          <a *ngIf="menuItem.isExternalLink" [href]="[menuItem.path]" target="_blank">
            <i [ngClass]="[menuItem.icon]"></i>
            <span class="menu-title">{{menuItem.title | translate }}</span>
            <span *ngIf="menuItem.badge && menuItem.badge != '' "
                  [ngClass]="[menuItem.badgeClass]">{{menuItem.badge}}</span>
          </a>
        </ng-container>
        <!-- Second level menu -->
        <ul *ngIf="menuItem.visible && menuItem.submenu.length > 0 " appSidebarDropdown class="menu-content">
          <li *ngFor="let menuSubItem of menuItem.submenu" [hasSub]="!!menuSubItem.class.includes('has-sub')"
              [ngClass]="{'has-sub': !!menuSubItem.class.includes('has-sub')}"
              [parent]="menuItem.title"
              [path]="menuSubItem.path" [routerLinkActive]="menuSubItem.submenu.length != 0 ? 'open' : 'active'"
              appSidebarlink
              level="{{level + 2}}">
            <ng-container *ngIf="menuSubItem.visible">
              <a *ngIf="menuSubItem.submenu.length > 0 && !menuSubItem.isExternalLink" appSidebarAnchorToggle>
                <i [ngClass]="[menuSubItem.icon]"></i>
                <span class="menu-title">{{menuSubItem.title | translate }}</span>
                <span *ngIf="menuSubItem.badge && menuSubItem.badge != '' "
                      [ngClass]="[menuSubItem.badgeClass]">{{menuSubItem.badge}}</span>
              </a>
              <a *ngIf="menuSubItem.submenu.length === 0 && !menuSubItem.isExternalLink" appSidebarAnchorToggle
                 routerLink="{{menuSubItem.path}}">
                <i [ngClass]="[menuSubItem.icon]"></i>
                <span class="menu-title">{{menuSubItem.title | translate }}</span>
                <span *ngIf="menuSubItem.badge && menuSubItem.badge != '' "
                      [ngClass]="[menuSubItem.badgeClass]">{{menuSubItem.badge}}</span>
              </a>
              <a *ngIf="menuSubItem.isExternalLink" routerLink="{{menuSubItem.path}}" target="_blank">
                <i [ngClass]="[menuSubItem.icon]"></i>
                <span class="menu-title">{{menuSubItem.title | translate }}</span>
                <span *ngIf="menuSubItem.badge && menuSubItem.badge != '' "
                      [ngClass]="[menuSubItem.badgeClass]">{{menuSubItem.badge}}</span>
              </a>
            </ng-container>
            <!-- Third level menu -->
            <ul *ngIf="menuSubItem.submenu.length > 0" appSidebarDropdown class="menu-content">
              <li *ngFor="let menuSubsubItem of menuSubItem.submenu" [hasSub]="false"
                  [ngClass]="config.layout.menuPosition === 'Side' ? menuSubsubItem.class : ''"
                  [parent]="menuItem.title"
                  [path]="menuSubsubItem.path" [routerLinkActiveOptions]="{exact: true}" appSidebarlink
                  level="{{level + 3}}"
                  routerLinkActive="active">
                <a *ngIf="!menuSubsubItem.isExternalLink; else externalSubSubLinkBlock" appSidebarAnchorToggle
                   routerLink="{{menuSubsubItem.path}}">
                  <i [ngClass]="[menuSubsubItem.icon]"></i>
                  <span class="menu-title">{{menuSubsubItem.title | translate }}</span>
                  <span *ngIf="menuSubsubItem.badge && menuSubsubItem.badge != '' "
                        [ngClass]="[menuSubsubItem.badgeClass]">{{menuSubsubItem.badge}}</span>
                </a>
                <ng-template #externalSubSubLinkBlock>
                  <a routerLink="{{menuSubsubItem.path}}" target="_blank">
                    <i [ngClass]="[menuSubsubItem.icon]"></i>
                    <span class="menu-title">{{menuSubsubItem.title | translate }}</span>
                    <span *ngIf="menuSubsubItem.badge && menuSubsubItem.badge != '' "
                          [ngClass]="[menuSubsubItem.badgeClass]">{{menuSubsubItem.badge}}</span>
                  </a>
                </ng-template>
              </li>
            </ul>
          </li>
        </ul>
      </li>
    </ul>
  </div>
</div>
<!-- Sidebar Content Ends -->
