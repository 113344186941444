import {Directive, ElementRef} from '@angular/core';

@Directive({
  selector: '[appInputPassword]'
})
export class InputPasswordDirective {

  private _shown = true;

  private eyeTemplateShow = '<div class="cursor-pointer input-group-append" title="Cliquez pour afficher le mot de passe."> <span class="input-group-text fa fa-eye" ></span> </div>'
  private eyeTemplateHide = '<div class="cursor-pointer input-group-append" title="Cliquez pour cacher le mot de passe."> <span class="input-group-text fa fa-eye-slash" ></span> </div>'

  constructor(private el: ElementRef) {
    this.setup();
  }

  toggle(span: HTMLElement) {
    this._shown = !this._shown;
    if (this._shown) {
      this.el.nativeElement.setAttribute('type', 'text');
      span.innerHTML = this.eyeTemplateHide;
    } else {
      this.el.nativeElement.setAttribute('type', 'password');
      span.innerHTML = this.eyeTemplateShow;
    }
  }

  setup() {
    const parent = this.el.nativeElement.parentNode;
    const span = document.createElement('span');

    span.innerHTML = this.eyeTemplateShow;
    span.addEventListener('click', (event) => {
      this.toggle(span);
    });
    parent.appendChild(span);
  }
}
