import {RouteInfo} from './vertical-menu.metadata';
import {YlAuthority} from '../data-model/enumerations';

// Sidebar menu Routes and data
export const ROUTES: RouteInfo[] = [
  {
    path: '/home',
    title: 'Accueil',
    icon: 'ft-home',
    class: '',
    badge: '',
    badgeClass: '',
    isExternalLink: false,
    submenu: [],
    authorities: [YlAuthority.CONNECT]
  }, {
    path: '/saisie',
    title: 'Saisie',
    icon: 'ft-edit',
    class: '',
    badge: '',
    badgeClass: '',
    isExternalLink: false,
    submenu: [],
    authorities: [YlAuthority.EDIT_RECETTE, YlAuthority.EDIT_DEPENSE]
  }, {
    path: '/depenses',
    title: 'Sorties',
    icon: 'ft-trending-down',
    class: '',
    badge: '',
    badgeClass: '',
    isExternalLink: false,
    submenu: [],
    authorities: [YlAuthority.VIEW_DEPENSE]
  }, {
    path: '/recettes',
    title: 'Entrées',
    icon: 'ft-trending-up',
    class: '',
    badge: '',
    badgeClass: '',
    isExternalLink: false,
    submenu: [],
    authorities: [YlAuthority.VIEW_RECETTE]
  }, {
    path: '/factures',
    title: 'Factures',
    icon: 'ft-paperclip',
    class: '',
    badge: '',
    badgeClass: '',
    isExternalLink: false,
    submenu: [],
    authorities: [YlAuthority.VIEW_FACTURE]
  }, {
    path: '/clients',
    title: 'Clients',
    icon: 'fa fa-handshake-o',
    class: '',
    badge: '',
    badgeClass: '',
    isExternalLink: false,
    submenu: [],
    authorities: [YlAuthority.SUPER_ADMIN, YlAuthority.LOCAL_ADMIN]
  },
  {
    path: '/categories',
    title: 'Catégories',
    icon: 'fa fa-columns',
    class: '',
    badge: '',
    badgeClass: '',
    isExternalLink: false,
    submenu: [],
    authorities: [YlAuthority.SUPER_ADMIN, YlAuthority.LOCAL_ADMIN]
  },
  {
    path: 'rapports',
    title: 'Impressions',
    icon: 'ft-bar-chart',
    class: '',
    badge: '',
    badgeClass: '',
    isExternalLink: false,
    submenu: [],
    authorities: [YlAuthority.IMPRESSION]
  }, {
    path: 'paiement',
    title: 'Paiement',
    icon: 'fa fa-money',
    class: '',
    badge: '',
    badgeClass: '',
    isExternalLink: false,
    submenu: [],
    authorities: [YlAuthority.PAIEMENT]
  }, {
    path: '',
    title: 'Paramètres',
    icon: 'ft-settings',
    class: 'has-sub',
    badge: '',
    badgeClass: 'badge badge-pill badge-danger float-right mr-1 mt-1',
    isExternalLink: false,
    authorities: [YlAuthority.SUPER_ADMIN],

    submenu: [
      {
        path: '/structures',
        title: 'Structures',
        icon: 'ft-arrow-right submenu-icon',
        class: '',
        badge: '',
        badgeClass: '',
        isExternalLink: false,
        submenu: [],
        authorities: [YlAuthority.SUPER_ADMIN]
      }, {
        path: '/comptes',
        title: 'Comptes',
        icon: 'ft-arrow-right submenu-icon',
        class: '',
        badge: '',
        badgeClass: '',
        isExternalLink: false,
        submenu: [],
        authorities: [YlAuthority.SUPER_ADMIN]
      }, {
        path: '/partenaires',
        title: 'Partenaires',
        icon: 'ft-arrow-right submenu-icon',
        class: '',
        badge: '',
        badgeClass: '',
        isExternalLink: false,
        submenu: [],
        authorities: [YlAuthority.SUPER_ADMIN]
      }, {
        path: '/users',
        title: 'Utilisateurs',
        icon: 'ft-arrow-right submenu-icon',
        class: '',
        badge: '',
        badgeClass: '',
        isExternalLink: false,
        submenu: [],
        authorities: [YlAuthority.SUPER_ADMIN]
      }, {
        path: '/profils',
        title: 'Profils',
        icon: 'ft-arrow-right submenu-icon',
        class: '',
        badge: '',
        badgeClass: '',
        isExternalLink: false,
        submenu: [],
        authorities: [YlAuthority.SUPER_ADMIN]
      }, {
        path: '/roles',
        title: 'Rôles',
        icon: 'ft-arrow-right submenu-icon',
        class: '',
        badge: '',
        badgeClass: '',
        isExternalLink: false,
        submenu: [],
        authorities: [YlAuthority.SUPER_ADMIN]
      }
    ]
  }
];
