<router-outlet>

</router-outlet>
<ngx-spinner *ngIf="spinnerVisible"
             bdColor='rgba(0, 0, 0, 0.8)'
             color="#fff"
             fullScreen=true
             name="http-loading"
             show="false"
             size="medium"
             type="ball-triangle-path">

</ngx-spinner>
