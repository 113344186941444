import {Injectable} from '@angular/core';
import {HttpClient, HttpErrorResponse, HttpHeaders} from '@angular/common/http';
import {IRole} from '../data-model/role';
import {Observable} from 'rxjs';
import {catchError, tap} from 'rxjs/operators';
import {IProfil} from '../data-model/profil';
import {URLS} from '../utils/contants';
import {NotificationService} from './notification.service';
import {IDashboard} from '../data-model/dashboard-data';
import {IStructure} from '../data-model/yl-structure';
import {ICategorie} from '../data-model/yl-categorie';
import {clearLoginLocalStorage, createRequestOption} from '../utils/utils';
import {IUser} from '../data-model/yl-user';
import {YlUpdatePasword} from '../data-model/update-password';
import {LocalStorageService, SessionStorageService} from 'ngx-webstorage';
import {YlReportInput} from '../data-model/yl-report-input';
import {ILog} from '../data-model/yl-log';
import {ICompte} from '../data-model/yl-compte';
import {ITransaction} from '../data-model/yl-transaction';
import {IEtatAbonnement} from '../data-model/yl-etat-abonnement';
import {IPartenariat} from '../data-model/yl-partenariat';
import {IOperation} from '../data-model/yl-operation';
import {IModeReglement} from '../data-model/yl-mode-reglement';
import {IClient} from '../data-model/yl-client';
import {IFacture} from '../data-model/yl-facture';

@Injectable({
  providedIn: 'root'
})
export class ComptasService {

  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    })
  };

  constructor(private http: HttpClient,
              private $localStorage: LocalStorageService,
              private $sessionStorage: SessionStorageService,
              private notificationService: NotificationService) {
  }

  /**
   * Récupérer la liste des comptes.
   */
  fetchComptes() {
    return this.http.get<ICompte[]>(URLS.COMPTE)
      .pipe(
        catchError(this.handleError<ICompte[]>('fetchComptes', []))
      );
  }

  /**
   * Récupérer la liste des rôles.
   */
  fetchRoles() {
    return this.http.get<IRole[]>(URLS.ROLE)
      .pipe(
        catchError(this.handleError<IRole[]>('fetchRoles', []))
      );
  }


  /**
   * Récupérer la liste des profils.
   */
  fetchProfils(filtre: any = {}) {
    return this.http.get<IProfil[]>(URLS.PROFIL, {params: createRequestOption(filtre)})
      .pipe(
        catchError(this.handleError<IProfil[]>('fetchProfils', []))
      );
  }

  /**
   * Vérifier si le compte est activé ou pas.
   */
  checkActivation(username: string) {
    return this.http.get<boolean>(`${URLS.USER}/${username}/is-activated`)
      .pipe(
        catchError(this.handleError<boolean>('checkActivation', false))
      );
  }

  /**
   * Modifier un profil.
   * @param item
   */
  putProfil(item: IProfil) {
    return this.http.put<IProfil>(URLS.PROFIL, item, this.httpOptions)
      .pipe(
        tap(i => {
          this.notificationService.success('Modification', 'YlProfil modifié avec succès')
        }),
        catchError(this.handleError<IProfil>('putProfil', {}))
      );
  }

  /**
   * Achever la réinitialisation du mot de passe.
   * @param item
   */
  completResetPassword(item: YlUpdatePasword) {
    return this.http.put<any>(`${URLS.USER}/complete-reset-password`, item, this.httpOptions)
      .pipe(
        tap(i => {
          this.notificationService.success('Activation', 'Compte activé avec succès.\nVeuillez vous reconnecter');
        }),
        catchError(this.handleError<YlUpdatePasword>('completResetPassword', {}))
      );
  }


  /**
   * Réinitialisation de mot de passe.
   * @param item
   */
  resetPassword(item: YlUpdatePasword) {
    return this.http.put<any>(`${URLS.USER}/change-password`, item, this.httpOptions)
      .pipe(
        tap(i => {
          this.notificationService.success('Mot de passe', 'Mot de passe changé avec succès.\nVeuillez vous reconnecter');
        }),
        catchError(this.handleError<YlUpdatePasword>('resetPassword', {}))
      );
  }


  /**
   * Demander l'envoi du code d'activation.
   * @param item
   */
  resendActivationCode(item: any) {
    return this.http.put<void>(`${URLS.USER}/request-reset-password`, item)
      .pipe(
        tap(i => {
          this.notificationService.success('Activation', 'Code envoyé avec succès')
        }),
        catchError(this.handleError<void>('resendActivationCode'))
      );
  }

  /**
   * Enregistrer un profil.
   * @param item
   */
  postProfil(item: IProfil) {
    return this.http.post<IProfil>(URLS.PROFIL, item, this.httpOptions)
      .pipe(
        tap(i => {
          this.notificationService.success('Enregistrement', 'YlProfil enregistré avec succès')
        }),
        catchError(this.handleError<IProfil>('postProfil', {}))
      );
  }

  /**
   * Supprimer un profil.
   * @param item
   */
  deleteProfil(item: IProfil) {
    return this.http.delete<string>(`${URLS.PROFIL}/${item.id}`, {responseType: 'text' as 'json'})
      .pipe(
        tap(i => {
          this.notificationService.success('Suppression', 'YlProfil supprimé avec succès')
        }),
        catchError(this.handleError<string>('deleteProfil', ''))
      );
  }

  /**
   * Supprimer un rôles.
   * @param item
   */
  deleteRole(item: IRole) {
    return this.http.delete<string>(`${URLS.ROLE}/${item.id}`, {responseType: 'text' as 'json'})
      .pipe(
        tap(i => {
          this.notificationService.success('Suppression', 'Rôle supprimé avec succès')
        }),
        catchError(this.handleError<string>('deleteRole', ''))
      );
  }

  /**
   * Modifier un profil.
   * @param item
   */
  putRole(item: IRole) {
    return this.http.put<IRole>(URLS.ROLE, item, this.httpOptions)
      .pipe(
        tap(i => {
          this.notificationService.success('Modification', 'Rôle modifié avec succès')
        }),
        catchError(this.handleError<IRole>('putRole', {}))
      );
  }

  /**
   * Enregistrer un profil.
   * @param item
   */
  postRole(item: IRole) {
    return this.http.post<IRole>(URLS.ROLE, item, this.httpOptions)
      .pipe(
        tap(i => {
          this.notificationService.success('Enregistrement', 'Rôle enregistré avec succès')
        }),
        catchError(this.handleError<IRole>('postRole', {}))
      );
  }


  /**
   * Récupérer la liste des structures.
   */
  fetchStructures(filtre = {}) {
    return this.http.get<IStructure[]>(URLS.STRUCTURE, {params: createRequestOption(filtre)})
      .pipe(
        catchError(this.handleError<IStructure[]>('fetchStructures', []))
      );
  }


  /**
   * Enregistrer un structure.
   * @param item
   */
  postStructure(item: IStructure) {
    return this.http.post<IStructure>(URLS.STRUCTURE, item, this.httpOptions)
      .pipe(
        tap(i => {
          this.notificationService.success('Enregistrement', 'Structure enregistré avec succès')
        }),
        catchError(this.handleError<IStructure>('postStructure', {}))
      );
  }

  /**
   * Modifier un structure.
   * @param item
   */
  putStructure(item: IStructure) {
    return this.http.put<IStructure>(`${URLS.STRUCTURE}/${item.id}`, item, this.httpOptions)
      .pipe(
        tap(i => {
          this.notificationService.success('Modification', 'Structure modifié avec succès')
        }),
        catchError(this.handleError<IStructure>('putStructure', {}))
      );
  }

  /**
   * Supprimer un structure.
   * @param item
   */
  deleteStructure(item: IStructure) {
    return this.http.delete<string>(`${URLS.STRUCTURE}/${item.id}`, {responseType: 'text' as 'json'})
      .pipe(
        tap(i => {
          this.notificationService.success('Suppression', 'Structure supprimé avec succès')
        }),
        catchError(this.handleError<string>('deleteStructure', ''))
      );
  }

  /**
   * Récupérer la liste des catégories.
   */
  fetchCategories(filtre: any = {}) {
    return this.http.get<ICategorie[]>(URLS.CATEGORIE, {params: createRequestOption(filtre)})
      .pipe(
        catchError(this.handleError<ICategorie[]>('fetchCategories', []))
      );
  }


  /**
   * Enregistrer une catégorie.
   * @param item
   */
  postCategorie(item: ICategorie) {
    return this.http.post<ICategorie>(URLS.CATEGORIE, item, this.httpOptions)
      .pipe(
        tap(i => {
          this.notificationService.success('Enregistrement', 'Categorie enregistré avec succès')
        }),
        catchError(this.handleError<ICategorie>('postCategorie', {}))
      );
  }

  /**
   * Modifier une catégorie.
   * @param item
   */
  putCategorie(item: ICategorie) {
    return this.http.put<ICategorie>(`${URLS.CATEGORIE}/${item.id}`, item, this.httpOptions)
      .pipe(
        tap(i => {
          this.notificationService.success('Modification', 'Categorie modifié avec succès')
        }),
        catchError(this.handleError<ICategorie>('putCategorie', {}))
      );
  }

  /**
   * Supprimer une catégorie.
   * @param item
   */
  deleteCategorie(item: ICategorie) {
    return this.http.delete<string>(`${URLS.CATEGORIE}/${item.id}`, {responseType: 'text' as 'json'})
      .pipe(
        tap(i => {
          this.notificationService.success('Suppression', 'Categorie supprimé avec succès')
        }),
        catchError(this.handleError<string>('deleteCategorie', ''))
      );
  }


  /**
   * Récupérer les infos du dashboard.
   */
  fetchDashboard() {
    return this.http.get<IDashboard>(`${URLS.DASHBOARD}`).pipe(
      catchError(this.handleError<IDashboard>('fetchDashboard', null))
    );
  }


  /**
   * Récupérer la liste des users.
   */
  fetchUsers(filtre = {}) {
    return this.http.get<IUser[]>(URLS.USER, {params: createRequestOption(filtre)})
      .pipe(
        catchError(this.handleError<IUser[]>('fetchUsers', []))
      );
  }


  /**
   * Enregistrer un user.
   * @param item
   */
  postUser(item: IUser) {
    return this.http.post<IUser>(URLS.USER, item, this.httpOptions)
      .pipe(
        tap(i => {
          this.notificationService.success('Enregistrement', 'Utilisateur enregistré avec succès')
        }),
        catchError(this.handleError<IUser>('postUser', {}))
      );
  }

  /**
   * Modifier un user.
   * @param item
   */
  putUser(item: IUser) {
    return this.http.put<IUser>(`${URLS.USER}/${item.id}`, item, this.httpOptions)
      .pipe(
        tap(i => {
          this.notificationService.success('Modification', 'Utilisateur modifié avec succès')
        }),
        catchError(this.handleError<any>('putUser', {}))
      );
  }

  /**
   * Supprimer un user.
   * @param item
   */
  deleteUser(item: IUser) {
    return this.http.delete<string>(`${URLS.USER}/${item.id}`, {responseType: 'text' as 'json'})
      .pipe(
        tap(i => {
          this.notificationService.success('Suppression', 'Utilisateur supprimé avec succès')
        }),
        catchError(this.handleError<string>('deleteUser', ''))
      );
  }

  /**
   * Récupérer la liste des logs.
   */
  fetchLogs(filtre = {}) {
    return this.http.get<ILog[]>(URLS.LOG, {params: createRequestOption(filtre)})
      .pipe(
        catchError(this.handleError<ILog[]>('fetchLogs', []))
      );
  }

  /**
   * Lancer une édition d'un état.
   * @param item
   */
  reporting(item: YlReportInput) {
    return this.http.post(URLS.REPORTING, item, {responseType: 'blob'})
      .pipe(
        catchError(this.handleError<any>('reporting', {}))
      );
  }

  /**
   * Récupérer la liste des transactions.
   */
  fetchTransactions(filtre = {}) {
    return this.http.get<ITransaction[]>(URLS.TRANSACTION, {params: createRequestOption(filtre)})
      .pipe(
        catchError(this.handleError<ITransaction[]>('fetchTransactions', []))
      );
  }


  /**
   * Valider une recharge de compte d'une Structure.
   * @param item
   */
  rechargeCompte(item: ITransaction) {
    return this.http.post<ITransaction>(`${URLS.COMPTE}/${item.idCompte}/recharge`, item, this.httpOptions)
      .pipe(
        tap(i => {
          this.notificationService.success('Recharge', 'Recharge effectuée avec succès')
        }),
        catchError(this.handleError<ITransaction>('rechargeCompte', {})));
  }

  /**
   * Valider un paiement d'abonnement.
   * @param idCompte
   */
  paiementAbonnement(idCompte: string) {
    return this.http.post<void>(`${URLS.COMPTE}/${idCompte}/paiement`, {}, this.httpOptions)
      .pipe(
        tap(i => {
          this.notificationService.success('Paiement', 'Paiement effectué avec succès')
        }),
        catchError(this.handleError<void>('paiementAbonnement'))
      );
  }

  /**
   * Vérifier l'abonnement d'une Structure.
   * @param idStructure
   */
  checkAbonnement(idStructure: string) {
    return this.http.get<IEtatAbonnement>(`${URLS.STRUCTURE}/${idStructure}/check-abonnement`)
      .pipe(
        tap(i => {
        }),
        catchError(this.handleError<IEtatAbonnement>('paiementAbonnement'))
      );
  }

  getAnneeScolaires() {
    // Initialiser la liste des années pour le rapport.
    // Il peut choisir 100 ans en arrière.
    const anneeActuelle = new Date().getFullYear()
    const yrs = [anneeActuelle]
    for (let i = 1; i <= 20; i++) {
      yrs.push(anneeActuelle - i)
    }
    return yrs;
  }

  /**
   * Récupérer la liste des partenariats.
   */
  fetchPartenariats(filtre: any) {
    return this.http.get<IPartenariat[]>(URLS.PARTENARIAT, {params: createRequestOption(filtre)})
      .pipe(
        catchError(this.handleError<IPartenariat[]>('fetchPartenariats', []))
      );
  }

  /**
   * Enregistrer un partenariat.
   * @param item
   */
  postPartenariat(item: IPartenariat) {
    return this.http.post<IPartenariat>(URLS.PARTENARIAT, item, this.httpOptions)
      .pipe(
        tap(i => {
          this.notificationService.success('Enregistrement', 'Partenariat enregistré avec succès')
        }),
        catchError(this.handleError<IPartenariat>('postPartenariat', {}))
      );
  }

  /**
   * Modifier un partenariat.
   * @param item
   */
  putPartenariat(item: IPartenariat) {
    return this.http.put<IPartenariat>(`${URLS.PARTENARIAT}/${item.id}`, item, this.httpOptions)
      .pipe(
        tap(i => {
          this.notificationService.success('Modification', 'Partenariat modifié avec succès')
        }),
        catchError(this.handleError<IPartenariat>('putPartenariat', {}))
      );
  }

  /**
   * Virement de fond pour un partenariat.
   * @param item
   */
  virementPartenariat(item: any) {
    return this.http.post<void>(`${URLS.PARTENARIAT}/virement`, item, this.httpOptions)
      .pipe(
        tap(i => {
          this.notificationService.success('Paiement', 'Virement effectué avec succès !')
        }),
        catchError(this.handleError<void>('virementPartenariat'))
      );
  }

  /**
   * Supprimer un partenariat.
   * @param item
   */
  deletePartenariat(item: IPartenariat) {
    return this.http.delete<string>(`${URLS.PARTENARIAT}/${item.id}`, {responseType: 'text' as 'json'})
      .pipe(
        tap(i => {
          this.notificationService.success('Suppression', 'Partenariat supprimé avec succès')
        }),
        catchError(this.handleError<string>('deletePartenariat', ''))
      );
  }

  /**
   * Récupérer la liste des opérations.
   */
  fetchOperations(filtre = {}) {
    return this.http.get<IOperation[]>(URLS.OPERATION, {params: createRequestOption(filtre)})
      .pipe(
        catchError(this.handleError<IOperation[]>('fetchOperations', []))
      );
  }

  /**
   * Enregistrer une opération.
   * @param item
   */
  postOperation(item: IOperation) {
    return this.http.post<IOperation>(URLS.OPERATION, item, this.httpOptions)
      .pipe(
        tap(i => {
          this.notificationService.success('Enregistrement', 'Operation enregistrée avec succès')
        }),
        catchError(this.handleError<IOperation>('postOperation', {}))
      );
  }

  /**
   * Modifier une opération.
   * @param item
   */
  putOperation(item: IOperation) {
    return this.http.put<IOperation>(`${URLS.OPERATION}/${item.id}`, item, this.httpOptions)
      .pipe(
        tap(i => {
          this.notificationService.success('Modification', 'Operation modifiée avec succès')
        }),
        catchError(this.handleError<IOperation>('putOperation', {}))
      );
  }

  /**
   * Supprimer une opération.
   * @param item
   */
  deleteOperation(item: IOperation) {
    return this.http.delete<string>(`${URLS.OPERATION}/${item.id}`, {responseType: 'text' as 'json'})
      .pipe(
        tap(i => {
          this.notificationService.success('Suppression', 'Opération supprimée avec succès')
        }),
        catchError(this.handleError<string>('deleteOperation', ''))
      );
  }

  /**
   * Récupérer la liste des modes règlements.
   */
  fetchModeReglements(filtre: any = {}) {
    return this.http.get<IModeReglement[]>(URLS.MODE_REGLEMENT, {params: createRequestOption(filtre)})
      .pipe(
        catchError(this.handleError<IModeReglement[]>('fetchModeReglements', []))
      );
  }

  /**
   * Enregistrer un mode règlement.
   * @param item
   */
  postModeReglement(item: IModeReglement) {
    return this.http.post<IModeReglement>(URLS.MODE_REGLEMENT, item, this.httpOptions)
      .pipe(
        tap(i => {
          this.notificationService.success('Enregistrement', 'Mode de règlement enregistré avec succès')
        }),
        catchError(this.handleError<IModeReglement>('postModeReglement', {}))
      );
  }

  /**
   * Modifier un mode de règlement.
   * @param item
   */
  putModeReglement(item: IModeReglement) {
    return this.http.put<IModeReglement>(`${URLS.MODE_REGLEMENT}/${item.id}`, item, this.httpOptions)
      .pipe(
        tap(i => {
          this.notificationService.success('Modification', 'Mode de règlement modifié avec succès')
        }),
        catchError(this.handleError<IModeReglement>('putModeReglement', {}))
      );
  }

  /**
   * Supprimer un mode règlement.
   * @param item
   */
  deleteModeReglement(item: IModeReglement) {
    return this.http.delete<string>(`${URLS.MODE_REGLEMENT}/${item.id}`, {responseType: 'text' as 'json'})
      .pipe(
        tap(i => {
          this.notificationService.success('Suppression', 'Mode de règlement supprimé avec succès')
        }),
        catchError(this.handleError<string>('deleteModeReglement', ''))
      );
  }

  /**
   * Récupérer la liste des clients.
   */
  fetchClients(filtre: any = {}) {
    return this.http.get<IClient[]>(URLS.CLIENT, {params: createRequestOption(filtre)})
      .pipe(
        catchError(this.handleError<IClient[]>('fetchClients', []))
      );
  }

  /**
   * Enregistrer un client.
   * @param item
   */
  postClient(item: IClient) {
    return this.http.post<IClient>(URLS.CLIENT, item, this.httpOptions)
      .pipe(
        tap(i => {
          this.notificationService.success('Enregistrement', 'Client enregistré avec succès')
        }),
        catchError(this.handleError<IClient>('postClient', {}))
      );
  }

  /**
   * Modifier un client.
   * @param item
   */
  putClient(item: IClient) {
    return this.http.put<IClient>(`${URLS.CLIENT}/${item.id}`, item, this.httpOptions)
      .pipe(
        tap(i => {
          this.notificationService.success('Modification', 'Client modifié avec succès')
        }),
        catchError(this.handleError<IClient>('putClient', {}))
      );
  }

  /**
   * Supprimer un client.
   * @param item
   */
  deleteClient(item: IClient) {
    return this.http.delete<string>(`${URLS.CLIENT}/${item.id}`, {responseType: 'text' as 'json'})
      .pipe(
        tap(i => {
          this.notificationService.success('Suppression', 'Client supprimé avec succès')
        }),
        catchError(this.handleError<string>('deleteClient', ''))
      );
  }

  /**
   * Récupérer la liste des factures.
   */
  fetchFactures(filtre: any = {}) {
    return this.http.get<IFacture[]>(URLS.FACTURE, {params: createRequestOption(filtre)})
      .pipe(
        catchError(this.handleError<IFacture[]>('fetchFactures', []))
      );
  }

  /**
   * Enregistrer une facture.
   * @param item
   */
  postFacture(item: IFacture) {
    return this.http.post<IFacture>(URLS.FACTURE, item, this.httpOptions)
      .pipe(
        tap(i => {
          this.notificationService.success('Enregistrement', 'Facture enregistrée avec succès')
        }),
        catchError(this.handleError<IFacture>('postFacture', {}))
      );
  }

  /**
   * Modifier une facture.
   * @param item
   */
  putFacture(item: IFacture) {
    return this.http.put<IFacture>(`${URLS.FACTURE}/${item.id}`, item, this.httpOptions)
      .pipe(
        tap(i => {
          this.notificationService.success('Modification', 'Facture modifiée avec succès')
        }),
        catchError(this.handleError<IFacture>('putFacture', {}))
      );
  }

  /**
   * Supprimer une facture.
   * @param item
   */
  deleteFacture(item: IFacture) {
    return this.http.delete<string>(`${URLS.FACTURE}/${item.id}`, {responseType: 'text' as 'json'})
      .pipe(
        tap(i => {
          this.notificationService.success('Suppression', 'Facture supprimée avec succès')
        }),
        catchError(this.handleError<string>('deleteFacture', ''))
      );
  }

  /**
   * Handle Http operation that failed.
   * Let the app continue.
   * @param operation - name of the operation that failed
   * @param result - optional value to return as the observable result
   */
  private handleError<T>(operation = 'operation', result?: T) {
    return (err: any): Observable<T> => {

      // TODO: send the error to remote logging infrastructure
      console.error(err); // log to console instead

      // TODO: better job of transforming error for user consumption
      console.error(`${operation} failed: ${err.message}`);
      if (err instanceof HttpErrorResponse) {
        if (err.status === 401) {
          clearLoginLocalStorage(this.$localStorage, this.$sessionStorage)
        }
        if (err.status === 400) {
          if (err.error) {
            this.notificationService.error('Erreur de données', err.error.message);
          } else {
            this.notificationService.error('Erreur de données', 'Les informations envoyées sont invalides.');
          }
        } else if (err.status === 405) {
          this.notificationService.error('Erreur de communication', 'Le type de méthode n\'est pas pris en charge.' +
            'L\'opération a échouée.');
        } else if (err.status === 500) {
          this.notificationService.error('Erreur interne', 'Nous avons rencontré une erreur interne. ')
        } else if (err.status === 504) {
          this.notificationService.error('Erreur de connexion', 'Le serveur est injoignable.')
        } else if (err.status === 0) {
          this.notificationService.error('Erreur de connexion', 'Vérifiez votre connexion internet.')
        } else {
          this.notificationService.error('Erreur inconnue', 'Cette erreur est inconnue. Veuillez rafraichir la page.')
        }
      }
      // Let the app keep running by returning an empty result.
      // return of(result as T);
      throw err;
    };
  }

}
