import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  OnDestroy,
  OnInit,
  Output,
  QueryList,
  ViewChild,
  ViewChildren
} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {LayoutService} from '../services/layout.service';
import {Subscription} from 'rxjs';
import {ConfigService} from '../services/config.service';
import {FormControl} from '@angular/forms';
import {LISTITEMS} from '../data-model/template-search';
import {Router} from '@angular/router';
import {AccountService} from '../auth/account.service';
import {IUser} from '../data-model/yl-user';
import {AuthServerProvider} from '../auth/auth-jwt.service';
import {NgxSpinnerService} from 'ngx-spinner';
import {ComptasService} from '../services/comptas.service';
import {IEtatAbonnement} from '../data-model/yl-etat-abonnement';
import {NotificationService} from '../services/notification.service';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit, AfterViewInit, OnDestroy {
  currentLang = 'en';
  selectedLanguageText = 'English';
  selectedLanguageFlag = './assets/img/flags/us.png';
  toggleClass = 'ft-maximize';
  placement = 'bottom-right';
  logoUrl = 'assets/img/logo.png';
  menuPosition = 'Side';
  isSmallScreen = false;
  searchOpenClass = '';
  transparentBGClass = '';
  hideSidebar = true;
  public isCollapsed = true;
  layoutSub: Subscription;
  configSub: Subscription;
  @ViewChild('search') searchElement: ElementRef;
  @ViewChildren('searchResults') searchResults: QueryList<any>;
  @Output()
  toggleHideSidebar = new EventEmitter<Object>();
  @Output()
  seachTextEmpty = new EventEmitter<boolean>();
  listItems = [];
  control = new FormControl();
  public config: any = {};
  currentUserInfo: IUser = null;
  etatAbonnement: IEtatAbonnement = null;
  protected innerWidth: any;

  constructor(public translate: TranslateService,
              private layoutService: LayoutService,
              private accountService: AccountService,
              private parametreService: ComptasService,
              private notificationService: NotificationService,
              private spinnerService: NgxSpinnerService,
              private authServerProvider: AuthServerProvider,
              private router: Router,
              private configService: ConfigService, private cdr: ChangeDetectorRef) {

    const browserLang: string = translate.getBrowserLang();
    translate.use(browserLang.match(/en|es|pt|de/) ? browserLang : 'en');
    this.config = this.configService.templateConf;
    this.innerWidth = window.innerWidth;

    this.layoutSub = layoutService.toggleSidebar$.subscribe(
      isShow => {
        this.hideSidebar = !isShow;
      });
  }

  ngOnInit() {
    this.listItems = LISTITEMS;

    if (this.innerWidth < 1200) {
      this.isSmallScreen = true;
    } else {
      this.isSmallScreen = false;
    }

    // Récuperer les informations de l'utilisateur.
    this.accountService.fetchCurrentUserInfo()
      .subscribe((res) => {
        this.currentUserInfo = res.body;
      }, (error) => {
        console.error(`Impossible de recupérer les informations de l'utilisateur`);
        this.currentUserInfo = null;
        this.redirectTo('/pages/login')
      });

    // Vérifier l'état d'abonnement.
    const currentCdeId = this.accountService.getCurrentStructureId();
    this.parametreService.checkAbonnement(currentCdeId)
      .subscribe(p => {
        this.etatAbonnement = p;
        if (p.upToDate) {
          if (p.message) {
            this.notificationService.pinned('warn', 'Votre abonnement expire bientôt', p.message)
          }
        } else {
          this.notificationService.pinned('error', 'Votre abonnement a expiré', p.message)
        }
      }, (err) => {
      });

  }

  ngAfterViewInit() {

    this.configSub = this.configService.templateConf$.subscribe((templateConf) => {
      if (templateConf) {
        this.config = templateConf;
      }
      this.loadLayout();
      this.cdr.markForCheck();

    })
  }

  ngOnDestroy() {
    if (this.layoutSub) {
      this.layoutSub.unsubscribe();
    }
    if (this.configSub) {
      this.configSub.unsubscribe();
    }
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.innerWidth = event.target.innerWidth;
    if (this.innerWidth < 1200) {
      this.isSmallScreen = true;
    } else {
      this.isSmallScreen = false;
    }
  }

  loadLayout() {

    if (this.config.layout.menuPosition && this.config.layout.menuPosition.toString().trim() !== '') {
      this.menuPosition = this.config.layout.menuPosition;
    }

    if (this.config.layout.variant === 'Light') {
      this.logoUrl = 'assets/img/logo-dark.png';
    } else {
      this.logoUrl = 'assets/img/logo.png';
    }

    if (this.config.layout.variant === 'Transparent') {
      this.transparentBGClass = this.config.layout.sidebar.backgroundColor;
    } else {
      this.transparentBGClass = '';
    }

  }

  onSearchKey(event: any) {
    if (this.searchResults && this.searchResults.length > 0) {
      this.searchResults.first.host.nativeElement.classList.add('first-active-item');
    }

    if (event.target.value === '') {
      this.seachTextEmpty.emit(true);
    } else {
      this.seachTextEmpty.emit(false);
    }
  }

  removeActiveClass() {
    if (this.searchResults && this.searchResults.length > 0) {
      this.searchResults.first.host.nativeElement.classList.remove('first-active-item');
    }
  }

  onEscEvent() {
    this.control.setValue('');
    this.searchOpenClass = '';
    this.seachTextEmpty.emit(true);
  }

  onEnter() {
    if (this.searchResults && this.searchResults.length > 0) {
      const url = this.searchResults.first.url;
      if (url && url !== '') {
        this.control.setValue('');
        this.searchOpenClass = '';
        this.router.navigate([url]);
        this.seachTextEmpty.emit(true);
      }
    }
  }

  redirectTo(value) {
    this.router.navigate([value]);
    this.seachTextEmpty.emit(true);
  }

  ToggleClass() {
    if (this.toggleClass === 'ft-maximize') {
      this.toggleClass = 'ft-minimize';
    } else {
      this.toggleClass = 'ft-maximize';
    }
  }

  toggleSearchOpenClass(display) {
    this.control.setValue('');
    if (display) {
      this.searchOpenClass = 'open';
      setTimeout(() => {
        this.searchElement.nativeElement.focus();
      }, 0);
    } else {
      this.searchOpenClass = '';
    }
    this.seachTextEmpty.emit(true);


  }


  toggleNotificationSidebar() {
    this.layoutService.toggleNotificationSidebar(true);
  }

  toggleSidebar() {
    this.layoutService.toggleSidebarSmallScreen(this.hideSidebar);
  }

  /**
   * Déconnexion.
   */
  logout() {
    this.showSpinner()
    this.authServerProvider.logout().subscribe()
    this.accountService.logout()
    setTimeout(() => {
      this.spinnerService.hide()
      this.redirectTo('/pages/login');
    }, 1000)
  }

  showSpinner() {
    this.spinnerService.show(undefined,
      {
        type: 'ball-triangle-path',
        size: 'medium',
        bdColor: 'rgba(0, 0, 0, 0.8)',
        color: '#fff',
        fullScreen: true
      });
  }

  updateProfil() {
    this.router.navigate(['update-profil']).then(() => {

    });
  }

}
