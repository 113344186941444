import {LOCALE_ID, NgModule} from '@angular/core';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {AngularFireAuthModule} from '@angular/fire/auth';

import {NgbDateParserFormatter, NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {HTTP_INTERCEPTORS, HttpClient, HttpClientModule} from '@angular/common/http';
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {NgxSpinnerModule} from 'ngx-spinner';
import {registerLocaleData} from '@angular/common';
import localeFr from '@angular/common/locales/fr';
import {PERFECT_SCROLLBAR_CONFIG, PerfectScrollbarConfigInterface, PerfectScrollbarModule} from 'ngx-perfect-scrollbar';

import {AppRoutingModule} from './app-routing.module';
import {SharedModule} from './shared/shared.module';
import {AppComponent} from './app.component';
import {ContentLayoutComponent} from './layouts/content/content-layout.component';
import {FullLayoutComponent} from './layouts/full/full-layout.component';

import {WINDOW_PROVIDERS} from './shared/services/window.service';
import {RouterModule} from '@angular/router';
import {NgSelectModule} from '@ng-select/ng-select';
import {ToastrModule} from 'ngx-toastr';
import {NgxDatatableModule} from '@swimlane/ngx-datatable';
import {NgxDropzoneModule} from 'ngx-dropzone';
import {ChartistModule} from 'ng-chartist';
import {NgbDateCustomParserFormatter} from './shared/utils/yl-date-utils';
import {AuthServerProvider} from './shared/auth/auth-jwt.service';
import {AuthGuardService} from './shared/auth/auth-guard.service';
import {AuthInterceptor} from './shared/interceptors/auth.interceptor';
import {LoaderInterceptor} from './shared/interceptors/loader.interceptor';
import {LoaderService} from './shared/utils/utils';

// the second parameter 'fr-FR' is optional
registerLocaleData(localeFr, 'fr-FR');


const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true,
  wheelPropagation: false
};

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

@NgModule({
  declarations: [AppComponent, FullLayoutComponent, ContentLayoutComponent],
  imports: [
    BrowserAnimationsModule,
    AppRoutingModule,
    SharedModule,
    HttpClientModule,
    AngularFireAuthModule,
    NgbModule,
    NgxSpinnerModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient]
      }
    }),
    PerfectScrollbarModule,
    RouterModule,
    NgSelectModule,
    ToastrModule.forRoot(),
    NgxDatatableModule,
    NgxDropzoneModule,
    ChartistModule
  ],
  providers: [
    AuthServerProvider,
    AuthGuardService,
    {provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true},
    {provide: HTTP_INTERCEPTORS, useClass: LoaderInterceptor, multi: true},
    {provide: PERFECT_SCROLLBAR_CONFIG, useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG},
    WINDOW_PROVIDERS,
    {provide: LOCALE_ID, useValue: 'fr-FR'},
    {provide: NgbDateParserFormatter, useClass: NgbDateCustomParserFormatter},
    LoaderService
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
