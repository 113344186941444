<!-- Theme customizer Starts-->
<div #customizer [ngClass]="{open: isOpen}" class="customizer d-none d-lg-none d-xl-block">
  <a (click)="closeCustomizer()" class="customizer-close">
    <i class="ft-x font-medium-3"></i></a><a (click)="toggleCustomizer()" class="customizer-toggle bg-primary"
                                             id="customizer-toggle-icon">
  <i class="ft-settings font-medium-1 spinner white align-middle"></i>
</a>
  <div [perfectScrollbar] class="customizer-content p-3 ps-container ps-theme-dark"
       data-ps-id="df6a5ce4-a175-9172-4402-dabd98fc9c0a">
    <h4 class="text-uppercase">Theme Customizer</h4>
    <p>Customize & Preview in Real Time</p>
    <!-- Layout Options Starts-->
    <div class="ct-layout">
      <hr>
      <h6 class="mb-3 d-flex align-items-center"><i class="ft-layout font-medium-2 mr-2"></i><span>Layout Options</span>
      </h6>
      <div class="layout-switch">
        <div class="radio radio-sm d-inline-block light-layout mr-3">
          <input (click)="customizerService.switchLayout('light', isBgImageDisplay)"
                 [checked]="config.layout.variant === 'Light'" id="ll-switch" name="layout-switch"
                 type="radio">
          <label for="ll-switch">Light</label>
        </div>
        <div class="radio radio-sm d-inline-block dark-layout mr-3">
          <input (click)="customizerService.switchLayout('dark', isBgImageDisplay)"
                 [checked]="config.layout.variant === 'Dark'" id="dl-switch" name="layout-switch"
                 type="radio">
          <label for="dl-switch">Dark</label>
        </div>
        <div class="radio radio-sm d-inline-block transparent-layout">
          <input (click)="customizerService.switchLayout('transparent', isBgImageDisplay)"
                 [checked]="config.layout.variant === 'Transparent'" id="tl-switch" name="layout-switch"
                 type="radio">
          <label for="tl-switch">Transparent</label>
        </div>
      </div>
      <!-- Layout Options Ends-->
    </div>
    <!-- Navigation Options Starts-->
    <div class="ct-menu-type">
      <hr>
      <h6 class="mb-3 d-flex align-items-center"><i class="ft-credit-card font-medium-2 mr-2"></i><span>Navigation
          Options</span></h6>
      <div class="menu-switch">
        <div class="radio radio-sm d-inline-block menu-side mr-3">
          <input (click)="customizerService.toggleMenuPosition('Side')"
                 [checked]="config.layout.menuPosition === 'Side'" id="menu-side" name="menu-switch"
                 type="radio">
          <label for="menu-side">Vertical</label>
        </div>
        <div class="radio radio-sm d-inline-block menu-top">
          <input (click)="customizerService.toggleMenuPosition('Top')" [checked]="config.layout.menuPosition === 'Top'"
                 id="menu-top" name="menu-switch"
                 type="radio">
          <label for="menu-top">Horizontal</label>
        </div>
      </div>
    </div>
    <!-- Sidebar Options Starts-->
    <!-- Navbar Types-->
    <div class="ct-navbar-type">
      <hr>
      <h6 class="mb-3 d-flex align-items-center"><i class="ft-more-horizontal font-medium-2 mr-2"></i><span>Navbar
          Type</span></h6>
      <div class="navbar-switch">
        <div class="radio radio-sm d-inline-block nav-static mr-3">
          <input (click)="customizerService.toggleNavbarType('Static')"
                 [checked]="config.layout.navbar.type === 'Static'" id="nav-static" name="navbar-switch"
                 type="radio">
          <label for="nav-static">Static</label>
        </div>
        <div class="radio radio-sm d-inline-block nav-fixed">
          <input (click)="customizerService.toggleNavbarType('Fixed')" [checked]="config.layout.navbar.type === 'Fixed'"
                 id="nav-fixed" name="navbar-switch"
                 type="radio">
          <label for="nav-fixed">Fixed</label>
        </div>
      </div>
    </div>
    <!-- Sidebar Options Starts-->
    <div class="ct-bg-color">
      <hr>
      <h6 class="sb-options d-flex align-items-center mb-3"><i class="ft-droplet font-medium-2 mr-2"></i><span>Sidebar
          Color Options</span></h6>
      <div class="cz-bg-color sb-color-options">
        <div class="row mb-3">
          <div *ngFor="let color of customizerService.lightDarkLayoutGradientBGColors" class="col px-2">
            <span (click)="customizerService.changeSidebarBGColor(color)" [attr.data-bg-color]="color.code"
                  [ngClass]="{'selected': color.active}" class="{{color.class}} d-block rounded"
                  style="width:30px; height:30px;">
            </span>
          </div>
        </div>
        <div class="row">
          <div *ngFor="let color of customizerService.lightDarkLayoutSolidBGColors" class="col px-2">
            <span (click)="customizerService.changeSidebarBGColor(color)" [attr.data-bg-color]="color.code"
                  [ngClass]="{'selected': color.active}" class="{{color.class}} d-block rounded"
                  style="width:30px; height:30px;">
            </span>
          </div>
        </div>
      </div>
      <!-- Sidebar Options Ends-->
      <!-- Transparent BG Image Ends-->
      <div class="tl-bg-img">
        <h6 class="d-flex align-items-center mb-3"><i class="ft-star font-medium-2 mr-2"></i><span>Background Colors
            with Shades</span></h6>
        <div class="cz-tl-bg-image row">

          <div *ngFor="let color of customizerService.transparentLayoutBGColorsWithShades" class="col-sm-3">
            <div (click)="customizerService.changeSidebarTransparentBGColor(color)" [attr.data-bg-image]="color.class"
                 [ngClass]="{'selected': color.active}"
                 class="rounded {{color.class}} ct-glass-bg">
            </div>
          </div>
        </div>
      </div>
      <!-- Transparent BG Image Ends-->
    </div>
    <!-- Sidebar BG Image Starts-->
    <div class="ct-bg-image">
      <hr>
      <h6 class="sb-bg-img d-flex align-items-center mb-3"><i class="ft-sidebar font-medium-2 mr-2"></i><span>Sidebar Bg
          Image</span></h6>
      <div class="cz-bg-image row sb-bg-img">
        <div *ngFor="let img of customizerService.lightDarkLayoutBGImages" class="col-2 px-2">
          <img (click)="customizerService.changeSidebarBgImage(img)" [ngClass]="{'selected': img.active}"
               [src]="img.src" alt="sidebar bg image" class="rounded"
               width="90"/>
        </div>
      </div>
      <!-- Transparent Layout Bg color Options-->
      <div class="tl-color-option">
        <h6 class="tl-color-options d-flex align-items-center mb-3"><i
          class="ft-droplet font-medium-2 mr-2"></i><span>Background Colors</span></h6>
        <div class="cz-tl-bg-color">
          <div class="row">
            <div *ngFor="let color of customizerService.transparentLayoutBGColors" class="col">
              <span (click)="customizerService.changeSidebarTransparentBGColor(color)"
                    [attr.data-bg-color]="color.class"
                    [ngClass]="{'selected': color.active}" class="{{color.class}} d-block rounded  ct-color-bg"
                    style="width:30px; height:30px;">
              </span>
            </div>
          </div>
        </div>
      </div>
      <!-- Transparent Layout Bg color Ends-->
    </div>
    <!-- Sidebar BG Image Toggle Starts-->
    <div class="ct-bg-image-toggler">
      <div class="togglebutton toggle-sb-bg-img">
        <hr>
        <div><span>Sidebar Bg Image</span>
          <div class="float-right">
            <div class="checkbox">
              <input (change)="bgImageDisplay($event)" checked class="cz-bg-image-display" id="sidebar-bg-img"
                     type="checkbox">
              <label for="sidebar-bg-img"></label>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Sidebar BG Image Toggle Ends-->
    <!-- Compact Menu Starts-->
    <div class="ct-compact-toggler">
      <hr>
      <div class="togglebutton">
        <div><span>Compact Menu</span>
          <div class="float-right">
            <div class="checkbox">
              <input (change)="customizerService.toggleCompactMenu($event)" [checked]="config.layout.sidebar.collapsed"
                     class="cz-compact-menu"
                     id="cz-compact-menu" type="checkbox">
              <label for="cz-compact-menu"></label>
            </div>
          </div>
        </div>
      </div>
      <!-- Compact Menu Ends-->
    </div>

    <!-- Sidebar Width Starts-->
    <div *ngIf="!config.layout.sidebar.collapsed" class="ct-sidebar-size">
      <hr>
      <p>Sidebar Width</p>
      <div class="cz-sidebar-width btn-group btn-group-toggle" data-toggle="buttons" id="cz-sidebar-width">
        <label [ngClass]="{'active': size === 'sidebar-sm'}" class="btn btn-outline-primary">
          <input (change)="changeSidebarWidth('sidebar-sm')" [checked]="size === 'sidebar-sm'" id="cz-btn-radio-1"
                 name="cz-btn-radio"
                 type="radio" value="sidebar-sm"><span>Small</span>
        </label>
        <label [ngClass]="{'active': size === 'sidebar-md'}" class="btn btn-outline-primary ">
          <input (change)="changeSidebarWidth('sidebar-md')" [checked]="size === 'sidebar-md'" id="cz-btn-radio-2"
                 name="cz-btn-radio"
                 type="radio" value="sidebar-md"><span>Medium</span>
        </label>
        <label [ngClass]="{'active': size === 'sidebar-lg'}" class="btn btn-outline-primary">
          <input (change)="changeSidebarWidth('sidebar-lg')" [checked]="size === 'sidebar-lg'" id="cz-btn-radio-3"
                 name="cz-btn-radio"
                 type="radio" value="sidebar-lg"><span>Large</span>
        </label>
      </div>
    </div>
    <!-- Sidebar Width Ends-->
  </div>
</div>
<!-- Theme customizer Ends-->
