import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
// @ts-ignore
import {LocalStorageService, SessionStorageService} from 'ngx-webstorage';
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {YLOCALSTORAGE} from '../utils/contants';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(private localStorage: LocalStorageService, private sessionStorage: SessionStorageService) {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (!request || !request.url || (/^http/.test(request.url))) {
      return next.handle(request);
    }

    const token = this.localStorage
      .retrieve(YLOCALSTORAGE.TOKEN_NAME) || this.sessionStorage.retrieve(YLOCALSTORAGE.TOKEN_NAME);
    if (!!token) {
      request = request.clone({
        setHeaders: {
          Authorization: 'Bearer ' + token
        }
      });
    }
    return next.handle(request);
  }
}
