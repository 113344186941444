import {Routes} from '@angular/router';
import {AuthGuardService} from '../auth/auth-guard.service';
import {ESensOperation, YlAuthority} from '../data-model/enumerations';

// Route for content layout with sidebar, navbar and footer for pages like Login, Registration etc...

export const EMBEDDED_LAYOUT_ROUTES: Routes = [
  {
    path: 'home',
    canActivate: [AuthGuardService],
    data: {
      authorities: [YlAuthority.CONNECT]
    },
    loadChildren: () => import('../../menu/home/home.module').then(m => m.HomeModule)
  }, {
    path: 'users',
    canActivate: [AuthGuardService],
    data: {
      authorities: [YlAuthority.LOCAL_ADMIN, YlAuthority.SUPER_ADMIN]
    },
    loadChildren: () => import('../../menu/users/user.module').then(m => m.UserModule)
  }, {
    path: 'profils',
    canActivate: [AuthGuardService],
    data: {
      authorities: [YlAuthority.LOCAL_ADMIN, YlAuthority.SUPER_ADMIN]
    },
    loadChildren: () => import('../../menu/profils/profil.module').then(m => m.ProfilModule)
  }, {
    path: 'roles',
    data: {
      authorities: [YlAuthority.SUPER_ADMIN]
    },
    canActivate: [AuthGuardService],
    loadChildren: () => import('../../menu/roles/role.module').then(m => m.RoleModule)
  }, {
    path: 'categories',
    data: {
      authorities: [YlAuthority.LOCAL_ADMIN]
    },
    canActivate: [AuthGuardService],
    loadChildren: () => import('../../menu/categorie/categorie.module').then(m => m.CategorieModule)
  }, {
    path: 'structures',
    canActivate: [AuthGuardService],
    data: {
      authorities: [YlAuthority.SUPER_ADMIN]
    },
    loadChildren: () => import('../../menu/structure/structure.module').then(m => m.StructureModule)
  }, {
    path: 'rapports',
    canActivate: [AuthGuardService],
    data: {
      authorities: [YlAuthority.IMPRESSION]
    },
    loadChildren: () => import('../../menu/rapports/rapport.module').then(m => m.RapportModule)
  }, {
    path: 'comptes',
    canActivate: [AuthGuardService],
    data: {
      authorities: [YlAuthority.SUPER_ADMIN]
    },
    loadChildren: () => import('../../menu/compte/compte.module').then(m => m.CompteModule)
  }, {
    path: 'update-profil',
    canActivate: [AuthGuardService],
    data: {
      authorities: [YlAuthority.CONNECT]
    },
    loadChildren: () => import('../../menu/update-profil/update-profil.module').then(m => m.UpdateProfilModule)
  }, {
    path: 'paiement',
    canActivate: [AuthGuardService],
    data: {
      authorities: [YlAuthority.PAIEMENT]
    },
    loadChildren: () => import('../../menu/paiement/paiement.module').then(m => m.PaiementModule)
  }, {
    path: 'partenaires',
    canActivate: [AuthGuardService],
    data: {
      authorities: [YlAuthority.SUPER_ADMIN]
    },
    loadChildren: () => import('../../menu/partenariat/partenariat.module').then(m => m.PartenariatModule)
  }, {
    path: 'saisie',
    canActivate: [AuthGuardService],
    data: {
      authorities: [YlAuthority.EDIT_DEPENSE, YlAuthority.EDIT_RECETTE]
    },
    loadChildren: () => import('../../menu/operation-saisie/saisie.module').then(m => m.SaisieModule)
  }, {
    path: 'depenses',
    canActivate: [AuthGuardService],
    data: {
      _type: ESensOperation.DEBIT,
      authorities: [YlAuthority.VIEW_DEPENSE]
    },
    loadChildren: () => import('../../menu/operation/operation.module').then(m => m.OperationModule)
  }, {
    path: 'recettes',
    canActivate: [AuthGuardService],
    data: {
      _type: ESensOperation.CREDIT,
      authorities: [YlAuthority.VIEW_RECETTE]
    },
    loadChildren: () => import('../../menu/operation/operation.module').then(m => m.OperationModule)
  }, {
    path: 'mode-reglements',
    canActivate: [AuthGuardService],
    data: {
      authorities: [YlAuthority.LOCAL_ADMIN]
    },
    loadChildren: () => import('../../menu/mode-reglement/mode-reglement.module').then(m => m.ModeReglementModule)
  }, {
    path: 'clients',
    canActivate: [AuthGuardService],
    data: {
      authorities: [YlAuthority.SUPER_ADMIN, YlAuthority.LOCAL_ADMIN]
    },
    loadChildren: () => import('../../menu/client/client.module').then(m => m.ClientModule)
  }, {
    path: 'factures',
    canActivate: [AuthGuardService],
    data: {
      authorities: [YlAuthority.VIEW_FACTURE]
    },
    loadChildren: () => import('../../menu/facture/facture.module').then(m => m.FactureModule)
  }
];
